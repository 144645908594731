@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/violet.css';

/* reset */
button {
    all: unset;
}

.CheckboxRoot {
    background-color: white;
    width: 25px;
    height: 25px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--black-a7);
    cursor: pointer;
}

.CheckboxRoot:hover {
    background-color: var(--violet-3);
}

.CheckboxIndicator {
    color: var(--violet-11);
}

.CheckboxLabel {
    color: black;
    padding-left: 15px;
    font-size: 15px;
    line-height: 1;
}