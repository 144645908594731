@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@font-face {
  font-family: "Inter", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
}

.inter {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
body {
  margin: 0;
  font-family: "Inter" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8fafc !important;
}
code {
  font-family: "Inter", sans-serif !important;
}

* {
  font-family: "Inter", sans-serif !important;
  box-sizing: border-box!important;

}
input[type="file"] {
  display: none;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper::-webkit-scrollbar {
  background-color: #fff;
  width: 5px;
  border-radius: 7px;
}

.borderGray {
  border: 1px solid #d1d5db;
}

.borderGray:focus {
  border: 1px solid #3b82f6;
}


.MuiPaper-root .css-hyum1k-MuiToolbar-root {
  background-color: #010001 !important;
  color: #fff;
}

.buttonPurple {
  background-color: #6f55ff;
  color: #fff;
  cursor: pointer;
}

.buttonPurple:hover {
  background-color: #664df2;
}
.bgPurple {
  background-color: #6f55ff;
}

.textPurple {
  color: #6f55ff;
}

.borderPurple {
  border-color: #6f55ff;
}
.sss {
  border:1px solid #6f55ff!important;

}

.bgf7fafc {
  background-color: #f7fafc;
}

.MuiPaper-root .css-hyum1k-MuiToolbar-root {
  background-color: #000000 !important;
  color: #fff;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  background-color: #6f55ff !important;
  border: 1px solid #6f55ff !important;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  background-color: #6f55ff !important;
  border: 1px solid #6f55ff !important;
}

.afterLine {
  position: relative;
}

.afterLine::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 10%;
  width: 90%;
  height: 3px;
  overflow: hidden;
  background-color: #EEF2F6;

}

.afterLine2 {
  position: relative;
}

.afterLine2::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 20%;
  width: 80%;
  height: 3px;
  overflow: hidden;
  background-color: #EEF2F6;
 
}

.afterLine3 {
  position: relative;
}

.afterLine3::after {
  content: "";
  position: absolute;
  top: 50%;
  left:0;
  width: 100%;
  height: 3px;
  overflow: hidden;
  background-color: #EEF2F6;
 z-index: -1;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(15px, -10px) scale(0.75)!important;
  background-color: #fff!important;
}
.css-1l3fqhq-MuiListItemIcon-root {
  margin-right: 10px!important;
}
.scrollBelirt {
  border: 1px solid #6f55ff!important;
  box-shadow: 0 0 3px 0 #6f55ff!important;
}

.form-check-input:checked {
  background-color: #6f55ff!important;  
  border-color: #6f55ff!important;
}


.trashDiv {
  visibility: hidden;
}
.trashMain:hover .trashDiv {
  visibility: visible;
}


.hoverMainText {
  display: none;
}

.hoverMain:hover .hoverMainText {
  display: block;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  background-color: #000000!important;
}